@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap');

.sidebar-wrapper-scroller::-webkit-scrollbar-thumb {
  background-color: #ff5233;
  /* Change this to your desired scrollbar thumb color */
  border-radius: 10px;
}

/* Scrollbar track (the background) */
.sidebar-wrapper-scroller::-webkit-scrollbar-track {
  background-color: transparent;
  /* Change this to your desired scrollbar track color */
}

/* Optional: Adjust scrollbar width and height */
.sidebar-wrapper-scroller::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.for-bg:before {
  content: "";
  background: linear-gradient(0deg, currentColor, hsla(0, 0%, 100%, 0));
  position: absolute;
  width: 100%;
  height: 100%;
}

select {
  font-family: 'Roboto', sans-serif;
}


body input[type='text'],
body input[type='email'],
input[type='number'],
input[type='date'],
input[type='password'],
input[type='search'],
textarea {
  font-size: 12px !important;
}

body input[type='text']:focus,
body input[type='email']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='date']:focus,
input[type='search']:focus,
textarea:focus {
  font-size: 12px !important;
}

body input[type='text']::placeholder,
body input[type='email']::placeholder,
body input[type='date']::placeholder,
input[type='number']::placeholder,
input[type='password']::placeholder,
input[type='search']::placeholder,
textarea::placeholder {
  font-size: 12px !important;
}

select {
  font-size: 12px !important;
}

.multiselect-container input.searchBox {
  opacity: 0;
}

@media(max-width:990px) {
  body select:focus {
    font-size: 16px !important;
  }

  select {
    font-size: 16px !important;
  }

  body input[type='text'],
  body input[type='number'],
  body input[type='email'],
  body input[type='date'],
  body input[type='search'],
  body input[type='password'],
  body textarea {
    font-size: 16px !important;
  }

  body select,
  body textarea,
  body input {
    font-size: 16px !important;
  }

  body input[type='text']:focus,
  body input[type='number']:focus,
  body input[type='date']:focus,
  body input[type='email']:focus,
  body input[type='password']:focus,
  body input[type='search']:focus,
  body textarea:focus {
    font-size: 16px !important;
  }

  body input[type='text']::placeholder,
  body input[type='number']::placeholder,
  body input[type='email']::placeholder,
  body input[type='password']::placeholder,
  body input[type='date']::placeholder,
  body input[type='search ']::placeholder,
  body textarea::placeholder {
    font-size: 16px !important;
  }


}


body .hide-focus {
  outline: unset !important;
  border: white !important;
}

body input[type='text']:focus,
body input[type='number']:focus,
body input[type='date']:focus,
body input[type='email']:focus,
body input[type='password']:focus,
body select:focus,
body textarea:focus {
  outline: 1px solid transparent;
  border: 1px solid rgb(19 95 194);
}


h2,
h3 {
  /* font-family: 'Cairo', sans-serif; */
  font-weight: 450;
}

.img-div-1:hover {
  filter: brightness(0) saturate(78%) invert(77%) sepia(282%) saturate(707%) hue-rotate(510deg) brightness(51%) contrast(196%);
}

.img-platform {
  width: 65px;
  height: 65px;
  object-fit: contain;
}

.img-platform-1 {
  width: 90px !important;
  height: 74px;
  object-fit: contain;
}

.img-platform-whatsapp {
  width: 90px;
  height: 80px;
}

.rotate-180 {
  transition-timing-function: ease;
  transition-duration: 0.125s;
  transition-delay: 0.1s;
}

pre code.hljs {
  padding: 0 !important;
}

span.hljs-tag {
  font-size: 13px;
}

.img-div-2 {
  display: flex;
  align-items: center;
  width: 140px;
}

.img-div-2 img {
  object-fit: contain;
}

/* for icon_animation */
.animation_icon_section .col_section {
  padding: 184px 0px;
}

.leftimg_sec.animation2 {
  animation: shake 15s infinite;
}

.leftimg_sec.animation {
  animation: shake2 15s infinite;
}

.leftimg_sec.animation3 {
  animation: shake3 15s infinite;
}

@keyframes shake {

  0%,
  100% {
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(-5px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateY(5px);
  }
}

@keyframes shake2 {

  0%,
  100% {
    transform: translateY(0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateY(-5px);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(5px);
  }
}

@keyframes shake3 {

  0%,
  100% {
    transform: translateY(0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateY(-3px);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(3px);
  }
}

@media (max-width: 768px) {
  .go-to-top {
    width: 100px !important;
    height: 50px !important;
    margin-right: auto;
    margin-left: auto;
    position: static !important;
    margin-top: 40px;
  }

  .newfooter {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }

  .img-div-2 {
    width: 120px;
  }

  .img-platform {
    width: 45px;
    height: 45px;
    object-fit: contain;
  }

  .img-platform-1 {
    width: 45px;
    height: 45px;
    object-fit: contain;
  }

  .animation_icon_section .col_section.content {
    padding: 0px 0px !important;
  }

  .animation_icon_section .col_section {
    padding: 75px 0px;
  }
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.my-side-hover {
  position: absolute;
  z-index: 9;
  top: -21px;
  width: 100%;
  left: 250px;
}

.selectdiv {
  position: relative;
}

select.custom-select {
  background-color: #ffffff;
  color: #000000;
  -webkit-appearance: none;
  padding: 8px;
  border-radius: 4px;
  outline: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  outline: none;
  background-repeat: no-repeat;
  font-weight: 500;
  background-position: right;
}

body .new_fade {
  color: #C7C6C7 !important;
  font-weight: 500;

}

body .searchWrapper {
  background-color: #ffffff;
  /* Set your desired background color */
  color: #000000;
  /* Set the text color */
  -webkit-appearance: none;
  /* Override Safari's default styling */
  padding: 6px;
  /* Adjust the padding as needed */
  border-radius: 4px;
  outline: none;
  /* Remove the default focus outline */
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  outline: none;
  background-repeat: no-repeat;
  background-position: right;
  width: 115px;
}

body .searchWrapper-live .searchWrapper {
  background-color: #ffffff;
  /* Set your desired background color */
  color: #000000;
  /* Set the text color */
  -webkit-appearance: none;
  /* Override Safari's default styling */
  padding: 6px;
  /* Adjust the padding as needed */
  border-radius: 4px;
  outline: none;
  /* Remove the default focus outline */
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  outline: none;
  background-repeat: no-repeat;
  background-position: right;
  width: 100%;
}

body .chip {
  font-size: 10px;
  line-height: 16px;
  margin-bottom: 4px;
  font-weight: 700;
  margin-right: 0;
  background-color: #0057ff;
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;
  padding: 4px 7px;
  width: 100px;
}

body .highlightOption {

  background-color: #0057ff;
  font-size: 10px;
}

body .multiSelectContainer ul {
  font-size: 10px;
}

select.custom-select::-ms-expand {
  display: none;
}

.singleblog ol {
  list-style: auto !important;
}

.chat-scroll div::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.chat-scroll div::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.chat-scroll div::-webkit-scrollbar-thumb {
  background: rgb(18, 17, 66);
  border-radius: 10px;
}

/* Handle on hover */
.chat-scroll div::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* timer animation */
}

.rainbow {
  align-items: center;
  background-color: #fff;
  border: 1px solid #8e9297;
  border-radius: 500px;
  color: var(--glue-grey-700);
  display: flex;
  font-weight: 500;
  height: 52px;
  justify-content: center;
  padding: 0 24px;
  position: relative;
  white-space: nowrap;
  max-width: 321px;
}

.facet-pill-border {
  fill: none;
  border-radius: 500px;
  height: 52px;
  left: -1px;
  pointer-events: none;
  position: absolute;
  top: -1px;
  width: calc(100% + 2px);
}

.facet-pill-border rect {
  stroke: #1e293b;
  stroke-width: 4px;
  height: 52px;
  visibility: hidden;
  width: 100%;
  animation: opacityChange 12s linear;
}

@keyframes opacityChange {
  0% {
    stroke-dasharray: 400.373;
  }

  100% {
    stroke-dasharray: 920.373;
  }
}

.div-anima .bg-black+.rainbow .facet-pill-border rect,
.start-rainbow .facet-pill-border rect {
  visibility: visible !important;
}

.tabs.overflow-x-scroll::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .img-platform-whatsapp {
    height: 55px;
  }

  .facet-pill-border rect {
    animation: opacityChange 12s linear;
  }

  .accordion__item:nth-child(2) .mobile_arroww .accordion__button:before,
  .accordion__item:nth-child(3) .mobile_arroww .accordion__button:before {
    display: none;
  }

  .active.start-rainbow {
    order: -2;
  }

  .border-animation {
    order: -1;
  }

  .trytempo a {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

select.custom-select {
  /* height: 45px !important; */
  background-color: #fff;
}

.trytempo a {
  padding: 2rem;
}

.calendly-popup iframe {
  height: 105% !important;
}

.fade-in-image {
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked+.slider:before {
  left: -3px;
}

.active-class::before {
  border-top: 3px solid #1a73e8;
  bottom: 0;
  content: "";
  display: block;
  height: 0;
  left: -36px;
  position: absolute;
  width: 100%;
  z-index: 0;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
  display: none;
}

.myparent input:hover+label {
  text-decoration: underline;
}

.desk_headermenupopup {
  display: none !important;
}

.top-0::-webkit-scrollbar {
  width: 0px;
}

.border-class {
  border: 2px solid #7ec1ec !important;
}

/* accordion tabs */

.tab_accordion_heading.active:before {
  position: absolute !important;
  right: 0 !important;
  top: 28px !important;
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 23px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg) !important;
  color: #fff !important;
  transition: transform 0.5s ease-in-out;
}

.tab_accordion_heading.start-rainbow:before {
  position: absolute !important;
  right: 0 !important;
  top: 28px !important;
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 23px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  color: #000;
  transition: transform 0.5s ease-in-out;
}

/* .dfdasdasd{
transition: all .4s ease;
} */
.accordionitem_panel {
  display: block !important;
  transition: transform 5s ease-in-out;
}

.accordionitem_panel .p-6 {
  min-height: 0;
  height: 0;
  overflow: hidden;
  transition: transform 5s ease-in-out;
}

.bg-background.rounded-lg .accordionitem_panel .p-6 {
  height: auto;
  transition: transform 5s ease-in-out;
}

.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.featurecards {
  padding: 1rem;
}

.featurecards:hover {
  /* border: 1px solid #000; */
  /* border-color: #09162A; */
  box-shadow: 0px 2px 10px 1px #09162a, 0 6px 7px -5px #09162a;
}

.featurecards_mobile:after {
  position: absolute !important;
  right: -20px !important;
  top: 15px !important;
  display: inline-block;
  content: "";
  height: 8px;
  width: 8px;
  margin-right: 23px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  color: #000;
  transition: transform 0.5s ease-in-out;
}

.featurecards_mobile2:after {
  position: absolute !important;
  right: -20px !important;
  top: 15px !important;
  display: inline-block;
  content: "";
  height: 8px;
  width: 8px;
  margin-right: 23px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg);
  color: #000;
  transition: transform 0.5s ease-in-out;
}

.workflowslider button.slick-arrow.slick-prev::before,
.workflowslider button.slick-arrow.slick-next::before {
  display: none;
}

.workflowslider button.slick-arrow.slick-prev {
  background-image: url("../public/workflowslider/left.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 65px;
  height: 65px;
  top: 0px;
  left: unset !important;
  right: 45px;
  z-index: 1;
}

.workflowslider button.slick-arrow.slick-next {
  background-image: url("../public/workflowslider/right.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 65px;
  top: 0px;
  height: 65px;
  right: -20px;
}

/* my csss */
/* .workflowslider li.slick-active {
  opacity: 1 !important;
  width: 20%;
  background: #97a0af;
  border-radius: 40px;
}
.workflowslider .slick-dots li { 
  opacity: 0;
  width: 13.8%;
  border-radius: 40px;
}
.workflowslider .slick-dots li.slick-active button:before {
  opacity: 0 ;
}
.workflowslider  .slick-dots li button { 
  width: 33px;
  height: 33px; 
}
.workflowslider ul.slick-dots {
  background: #ececed6e;
  border-radius: 40px;    width: 92%;
  left: 17px;
} */

.rdw-editor-toolbar {
  margin-bottom: 0px !important;
  border: 1px solid rgb(192, 192, 192) !important;
  border-bottom: 0px !important;
  border-radius: 7px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rdw-editor-toolbar {
  margin-bottom: 0px !important;
  border: 1px solid rgb(192, 192, 192) !important;
  border-bottom: 0px !important;
  border-radius: 7px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

/* .learnmore_integration_btn {
  height: 0;
  transition: transform 400ms ease 0s;
}
.integration_cards:hover .learnmore_integration_btn {
  height: auto;
  transition: transform 400ms ease 0s;
} */

.new_input {
  border-radius: 4px;
  padding: 8px;
  outline: none;
  font-weight: 500;
  border: 1px solid #C7C6C7;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  transition: none;
  text-overflow: ellipsis;
}

.new_input::placeholder {
  color: #C7C6C7;

}

.new_input:focus {
  box-shadow: none !important;
}

.new_input_label {
  font-size: 12px;
  color: #555;
  font-weight: 500;
}

.marque_section {
  -webkit-mask-image: linear-gradient(90deg, #0000, #fff 5%, #fff 95%, #0000);
}

.marque_card:hover {
  box-shadow: 0 0 1px #181d263d, 0 6px 8px #181d2614, 0 18px 32px #0000001a;
  border-radius: 8px;
}

/* sticky sidebar */
.sidebar {
  flex: 0 0 33.3333%;
  background: gray;
}

.sidebar__widget {
  position: sticky;
  top: 0;
  background: yellow;
  height: 250px;
}

.market-border::before {
  background: #fd181a;
  content: "";
  position: absolute;
  top: 0;
  height: 3px;
  opacity: 0;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  left: 2px;
  right: 2px;
}


.active2::before {
  background: #fd181a;
  content: "";
  position: absolute;
  top: 0;
  height: 4px;
  opacity: 1;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  left: 2px;
  right: 2px;
  border-radius: 20px;
}

.active2::after {
  width: 20px;
  height: 20px;
  background: #fff;
  content: "";
  position: absolute;
  bottom: -11px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  border-color: #fd181a;
  opacity: 1;
}

.active2 {
  border: 1px solid #fd181a;
  border-radius: 10px;
}

.img1 {
  position: absolute;
  left: -70px;
  top: -156px;
  width: 100px;
  height: 100px;
}

.img2 {
  position: absolute;
  right: -110px;
  top: -156px;
  width: 100px;
  height: 100px;
}

.img3 {
  position: absolute;
  left: -25px;
  width: 100px;
  height: 100px;
  top: 100px;
}

.img4 {
  position: absolute;
  right: -65px;
  top: 100px;
  width: 100px;
  height: 100px;
}


.connect-heading-line {
  position: relative;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 8px 4px;
}

.connect-heading-line2 {
  position: relative;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 8px 4px;
}

.connect-heading-line::before {
  position: absolute;
  top: 50%;
  left: -8px;
  content: "";
  width: 4px;
  height: 4px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #fff;
}


.connect-heading-line::before {
  position: absolute;
  top: 50%;
  left: -8px;
  content: "";
  width: 4px;
  height: 4px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #fff
}

.connect-heading-line::after {
  position: absolute;
  top: 50%;
  left: -8px;
  content: "";
  width: 10px;
  height: 10px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #05bdba;
  opacity: 0;
  animation: connectHeadingDotLeft 5s infinite linear
}

.connect-heading-line[data-line-position=right]::after {
  animation: connectHeadingDotRight 5s infinite linear
}

@keyframes connectHeadingDotLeft {
  0% {
    opacity: 1
  }

  50% {
    opacity: 1;
    left: 95%
  }

  51% {
    opacity: 1;
    left: 95%
  }

  55% {
    opacity: 0;
    left: 95%
  }
}

@keyframes connectHeadingDotRight {
  49% {
    opacity: 0
  }

  50% {
    opacity: 1;
    left: -8px
  }

  95% {
    opacity: 1;
    left: 100%
  }

  96% {
    opacity: 1;
    left: 100%
  }

  100% {
    opacity: 0;
    left: 100%
  }
}

body .cursor-pointer {
  cursor: pointer !important;
}

.stripe_cards:hover .stripe_card_title {
  display: none;
}

.stripe_cards .stripe_card_btn {
  display: none;
}

.stripe_cards:hover .stripe_card_btn {
  display: block;
  transition: opacity 400ms ease 0s;
}

.stepper-dots {
  background-color: #d9d9d9;
  border: 2px solid #d9d9d9;
  height: 10px;
  width: 10px;
}

.stepper-plus-icon {
  --saf-0: rgba(var(--sk_foreground_low_solid, 221, 221, 221), 1);
  align-items: center;
  background-color: rgba(var(--sk_primary_background, 255, 255, 255), 1);
  border: 2px solid var(--saf-0);
  display: flex;
  height: 35px;
  justify-content: center;
  min-width: 0;
  opacity: 0;
  padding: 0;
  pointer-events: none;
  transition: opacity 80ms linear;
  width: 35px;
}

.stpper-lines {
  background-color: initial;
  height: 0;
  position: absolute;
  transition: background-color .16s cubic-bezier(.36, .19, .29, 1), height .16s cubic-bezier(.36, .19, .29, 1);
  width: 100%;
  z-index: 1;
}

.stepper-spacer {
  background-color: #d9d9d9;
  height: 24px;
  transition: height .16s cubic-bezier(.36, .19, .29, 1);
  width: 4px;
}

.stepper-plus-icon:focus,
.stepper-plus-icon:hover,
.stepper-container:hover .stepper-plus-icon {
  border-radius: 100%;
  opacity: 1;
  pointer-events: all;
}

.stepper-plus-icon:focus,
.stepper-plus-icon:hover {
  background-color: #f8f8f8;
  pointer-events: all;
}

.stepper-plus-icon:focus+.stpper-lines,
.stepper-plus-icon:hover+.stpper-lines {
  background-color: #0057ff;
  height: 2px;
}

.stepper-plus-icon:hover {
  background: #f8f8f8;
  box-shadow: 0 1px 3px 0 #00000014;
}


.stepper-plus-icon:focus~.stepper-spacer,
.stepper-plus-icon:hover~.stepper-spacer {
  height: 34px;
}

.stripe-int-icon {

  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #e5e5e5;
  border-radius: 15px;
}

.stripe-int-icon-1 {

  height: 70px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #e5e5e5;
  border-radius: 15px;
}

.article_left_accordion .accordion__panel {
  padding: 0 17px 13px 20px !important;
}

.article_pageslidebar .accordion__panel {
  padding: 0 17px 13px 20px !important;
}

/* .article_pageslidebar .accordion:nth-child(8) .accordion__button::before{
  display: none;
} */
@media screen and (max-width: 768px) {
  .sliderMaine .slick-track {
    display: flex;
    padding-bottom: 0px !important;
  }
  
  /* Your CSS rules for phones go here */
  .my-page {
    overflow-y: scroll;
  }
}

/* START:: For Learning Center Side Animation */
@keyframes side-drawer-opacity-animation {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes external__content__progress-bar-pulse {
  0% {
    background-color: #222
  }

  25% {
    background-color: #434343
  }

  50% {
    background-color: #e8e8e8
  }

  75% {
    background-color: #757575
  }

  100% {
    background-color: #434343
  }
}

.rightSlideAnimations {
  animation: side-drawer-opacity-animation .3s ease-in-out;
}

.blink-in-progress-animation {
  animation-name: external__content__progress-bar-pulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transition: all 1s;
  border-radius: 10px
}

.rhap_container .rhap_main.rhap_stacked {
  flex-direction: row;
}

.rhap_container .rhap_stacked .rhap_controls-section {
  margin-top: 0px;
}


@media(min-width:991px) {
  body .data-table-class {
    overflow: unset !important;
  }

  .table_overflow {
    overflow: unset;
  }
}



@media(max-width:990px) {
  .table_overflow {
    overflow-x: scroll !important;
  }

  body .hide-body {
    overflow: hidden !important;
  }

  .headerAlignment {
    display: -webkit-inline-box !important;
  }
}

.chilipiperh2gradient {
  background: linear-gradient(90.13deg, #ff6736 .11%, #ff6736 25.06%, #e569f9 50%, #8673ff 74.8%, #8673ff 99.76%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-weight: 600 !important;
}


.newfooter {
  color: #24233f;
  text-align: center;
  object-fit: cover;
  background-color: #1a1e30;
  background-image: url("https://assets-global.website-files.com/61c9fe00acd90d7271f7014e/647a0fce338f2e6bb181feaa_Footer-Background.svg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: flex-start;
  margin-bottom: 33px;
  padding-top: 72px;
  padding-bottom: 20px;
}

.go-to-top {
  width: 160px;
  height: 80px;
  cursor: pointer;
  background-color: #ff5721;
  border-top-left-radius: 16px;
  border-top-right-radius: 17px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0px;
  left: auto;
  right: 120px;
}

.special .grid.grid-cols-1.overflow-y-scroll::-webkit-scrollbar {
  width: 0;
}

.sliderMaine .slick-slide .mt-8 {
  background-color: #fff;
  padding: 25px;
  /* box-shadow: 0px 48px 64px rgba(0, 26, 94, 0.07); */
}

.sliderMaine .slick-track {
  display: flex;
  padding-bottom: 80px;
}

.sliderMaine button.slick-arrow.slick-prev,
.sliderMaine button.slick-arrow.slick-next {
  margin: 0px auto;
  bottom: 0;
  top: unset !important;

}

.slick-prev:before,
.slick-next:before {
  color: #fb8a22 !important;
}

.sliderMaine button.slick-arrow.slick-prev {
  left: 640px;
}

.sliderMaine button.slick-arrow.slick-next {
  right: 645px;
}


.mobile_arroww .accordion__button {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 30px 21px;
  background-color: transparent;
}

.accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
  margin-right: 0px;
}

.mobile_arroww .accordion__button::before {
  color: #fe9327;
  margin-top: 6px;
    content: '+' !important;
    transform: unset;
    border: 0px;
    font-size: 28px;
    position: relative;
    top: -15px;
}

.mobile_arroww .accordion__button::after {
  margin-left: 20px
}

img.firs-arr {
  filter: brightness(1000%);
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 18%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  transform: translate(-86px);
  margin-top: 15px;
}

.shadow-lg:hover img.firs-arr {
  transform: unset;
  filter: unset;
}

img.firs-arr,
img.second-arr {
  transition: 0.2s;
}

img.second-arr {
  max-width: 24px;
  transition: all .25s;
}

.shadow-lg:hover img.second-arr {
  transform: translateX(80px);
  opacity: 0;
}

.stick-right {
  position: sticky;
  top: 80px;
}

/* demo animations */
.getademo_animation {
  position: relative;
  box-shadow: none !important;
  position: relative;
  overflow: hidden;
  z-index: 10;
  background-color: #ff5721;
  background-image: linear-gradient(35deg, #ff5721, #fc8e0d);
}

.getademo_animation:hover {
  outline: 0;
}

.getademo_animation::before {
  content: "";
  background: #252c47;
  position: absolute;
  width: 130%;
  height: 200%;
  z-index: -1;
  left: -15%;
  border-radius: 100%;
  transition: 0.2s;
  bottom: -200%;
}

.getademo_animation:hover::before {
  bottom: -50%;
}

/* demo animations2 */
.learnmore_animation {
  position: relative;
  box-shadow: none !important;
  position: relative;
  overflow: hidden;
  z-index: 10;
}

.learnmore_animation:hover {
  outline: 0;
}

.learnmore_animation::before {
  content: "";
  background: #ff5721;
  position: absolute;
  width: 130%;
  height: 200%;
  z-index: -1;
  left: -15%;
  border-radius: 100%;
  transition: 0.2s;
  bottom: -200%;
}

.learnmore_animation:hover::before {
  bottom: -50%;
}

/* integration card animation */
.integration_card_ani {
  transform: translate3d(0px, 0px, 0px) scale3d(1.03, 1.03, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  transform-style: preserve-3d;
  transition: 0.4s;
}

.integration_card_ani:hover {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  transform-style: preserve-3d;
  transition: 0.4s;
}

.checkout_gradient_img {
  background-image: url('https://assets-global.website-files.com/61c9fe00acd90d7271f7014e/6421b0e5699d1c6a7d2280d4_Dark%20Header%20Background.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 35px;
}

.menu_link_arrow {
  width: 23px;
  opacity: 0;
  transition: all .2s;
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 6px;
  transform: translate(0, -50%);
}

.menu_link_main:hover .menu_link_arrow {
  opacity: 1;
  right: 0;
}

.forBoredr div {
  border-right: 1px solid #d9d9d9 !important;
  border: 1px solid #d9d9d9 !important;
  padding: 8px 14px !important;
}

.forBoredrbtm {
  border: 1px solid #d9d9d9 !important;
  padding: 8px 15px !important;
}


.hidescrollbar::-webkit-scrollbar {
  display: none !important;
}

.special .slick-arrow.slick-prev{
  z-index: 1;
}

/* 
Css for mobile view */
.nav-accordian .accordion__item {
  border-bottom: 0px  !important;
  border-top:0px !important;
}

.mobile_arroww .accordion__button {
  padding: 15px 20px !important;
}

body .nav-accordian .accordion__button:before {
  top: 16px !important;
}

.mobileview {
  padding: 9px;
  background: white;
  height: 100% !important;
  padding-bottom: 2rem;
  overflow-y: auto;
  border-radius: 20px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  width: 95%;
  margin: 0px auto;
}

.removescroll::-webkit-scrollbar{width: 2px;}
.fXTWVm {
  border: 0.5px dashed #2196f3 !important;
}


.rdt_TableRow {
  padding: 0px !important;
}
.linkhr p.flex.relative::before {
  background: #ff5722;
  width: 0;
  height: 2px;
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  transition: 0.2s;
}

.linkhr li:hover p.flex.relative::before{width: 50%;}

.admin-sidebar-wrapper:hover .admin-sidebar-logo {
  opacity: 0;
}

.admin-sidebar-wrapper:hover .admin-sidebar-title {
  margin-left: 0px;
}

.admin-sidebar-title {
  transition: margin-left 0.15s ease 0s;
  margin-left: 37px;
}

.rdt_TableRow{
  padding: 12px 0;
}

body .rhap_container {
  box-shadow: none !important;
  background-color: #fff;
}

body .rhap_time {
  font-size: 12px !important;
}

body .rhap_progress-filled,
.rhap_progress-indicator,
.rhap_progress-bar-show-download {
  background-color: #0057ff !important;
}

body .rhap_main-controls-button {
  color: #0057ff;
}

.closeIcon {
  cursor: pointer;
  float: none;
  height: 13px;
  margin-left: 0;
}

body .rhap_play-pause-button {
  font-size: 20px;
  width: 24px;
  height: 29px;
  margin-left: 10px;
}

body .rhap_additional-controls {
  display: none;
}

body .rhap_progress-indicator {
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  width: 17px;
  height: 17px;
  margin-left: -10px;
  top: -6px;
  background: #868686;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
}

body .rhap_controls-section {
  flex: unset;
}


div[data-column-id="Role"] {
  display: flex;
  justify-content: center;
}

div[data-column-id="Action"] {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  div[data-column-id="Role"] {
    display: none;

  }

  div[data-column-id="Action"] {
    display: flex;
    justify-content: start !important;
  }
}

@media (min-width: 768px) {

  .centered-table .rdt_TableCell {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .centered-table .rdt_TableCol_Sortable {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


/* new table css */

.limiter {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.wrap-table100 {
  width: 100%;
}

.wrap-table100 table {
  border-spacing: 1;
  border-collapse: collapse;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.wrap-table100 table tbody tr {
  height: 50px;
}

.wrap-table100 table td,
.wrap-table100 table th {
  text-align: left;
  padding-left: 8px;
}

.wrap-table100 .column1 {
  width: 260px;
  padding-left: 40px;
}

.wrap-table100 .column2 {
  width: 160px;
}

.wrap-table100 .column3 {
  width: 245px;
}

.wrap-table100 .column4 {
  width: 110px;
  text-align: right;
}

.wrap-table100 .column5 {
  width: 170px;
  text-align: right;
}

.wrap-table100 .column6 {
  width: 222px;
  text-align: right;
  padding-right: 62px;
}

.wrap-table100 tbody tr:hover {
  color: #555;
  background-color: #f5f5f5;
  cursor: pointer;
}

.wrap-table100 tbody tr:nth-child(2n) {
  background-color: #f5f5f5;
}

@media screen and (max-width: 992px) {
  .wrap-table100 .wrap-table100 {
    width: 100%;
  }

  .wrap-table100 table {
    border-spacing: 1;
    border-collapse: collapse;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .wrap-table100 table thead {
    display: none;
  }

  .wrap-table100 table tbody tr {
    height: auto;
    padding: 37px 0;
    display: block;
  }

  .wrap-table100 table tbody tr td::before {
    font-size: 14px;
    color: #999;
    line-height: 1.2;
    font-weight: 700;
    position: absolute;
    left: 30px;
  }

  .wrap-table100 .column4,
  .wrap-table100 .column5,
  .wrap-table100 .column6,
  .wrap-table100 .column1,
  .wrap-table100 .column2,
  .wrap-table100 .column3 {
    width: 100%;
    text-align: left !important;
    position: relative;
  }

  .wrap-table100 table tbody tr td {
    padding-left: 110px !important;
    margin-bottom: 24px;
    display: block;
    text-align: left !important;

  }

  .wrap-table100 table tbody tr td:nth-child(1)::before {
    content: "Logo";
  }

  .wrap-table100 table tbody tr td:nth-child(2)::before {
    content: "Email";
  }

  .wrap-table100 table tbody tr td:nth-child(3)::before {
    content: "Name";
  }

  .wrap-table100 table tbody tr td:nth-child(4)::before {
    content: "Contact";
  }

  .wrap-table100 table tbody tr td:nth-child(5)::before {
    content: "Role";
  }

  .wrap-table100 table tbody tr td:nth-child(6)::before {
    content: "Action";
  }
}

textarea#answer,
textarea#negative_answer {

  width: 100%;
  border: 1px solid #00000059;
  padding: 3px;
  border-radius: 3px;
}

body .rdt_Pagination select:focus{
	border: 1px solid rgb(19 95 194) !important;
}
body .rdt_Pagination select{
	border: 1px solid transparent !important;
}
/* contentful */

.contentful-wrapper h1{
font-size: 30px;
font-weight: 600;
margin-top: 20px;
margin-bottom: 20px;
}

.contentful-wrapper h2{
  font-size: 25px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
  }
  

.contentful-wrapper p{
  margin-top: 20px;
  margin-bottom: 20px;
}

.contentful-wrapper ul{
  list-style: disc;
}


/*Contentful css  */
@media screen and (max-width:768px) {


  .chatfeature_slider .slick-dots {
    width: 77%;
    top: 25px;
  }
}
@media(max-width:991px) {
  .chatfeature_slider {
    position: relative;
  }
  
  .chatfeature_slider button.slick-arrow.slick-prev::before,
  .chatfeature_slider button.slick-arrow.slick-next::before {
    display: none;
  }
  
  .chatfeature_slider button.slick-arrow.slick-prev {
    background-image: url("/feature-slider/arrow_down.webp");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50px;
    left: unset;
    right: 43px;
    width: 50px;
    top: 300px;
  }
  
  .chatfeature_slider button.slick-arrow.slick-next {
    background-image: url("/feature-slider/arrow_up.webp");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50px;
    right: -10px;
    width: 50px;
    top: 300px;
  }
  
  .chatfeature_slider .slick-dots {
    display: flex !important;
    width: 87%;
    position: relative;
    top: 34px;
  }
  
  .chatfeature_slider .slick-dots li button {
    width: 80%;
  }
  
  .chatfeature_slider .slick-dots li button {
    background: transparent;
    border-radius: 25px;
    height: 4px;
    padding: 0;
    width: 100%;
  }
  
  .chatfeature_slider .slick-dots li {
    width: 80%;
  }
  
  .chatfeature_slider .slick-dots li button::before,
  .hero_slider .slick-dots li.slick-active button::before {
    color: transparent;
    width: 100%;
  }
  
  .chatfeature_slider .slick-dots li.slick-active button {
    background: #fff;
  }

}
.superChare::-webkit-scrollbar-thumb {
  /* Change this to your desired scrollbar thumb color */
  width: 0px  !important;
}
.superChare::-webkit-scrollbar {
  width: 0 !important;  /* Remove scrollbar space */
  background: transparent !important; /* Optional: just make scrollbar invisible */
}
.my-class{
    overflow-y: scroll;
}